import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 65 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "32.5",
      cy: "32.688",
      r: "32",
      fill: "#fff"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "32.5",
      cy: "32.688",
      r: "12",
      fill: "#FFC6B4"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "32.5",
      cy: "32.688",
      r: "20",
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }, null, -1)
  ])))
}
export default { render: render }