import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 65 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "32.5",
      cy: "32.688",
      r: "32",
      fill: "#fff"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#27C0B9",
      d: "M15.5 22.188a7.5 7.5 0 0 1 15 0v26.5h-15z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FCDE6C",
      d: "M34.5 34.188a7.5 7.5 0 0 1 15 0v14.5h-15z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-width": "2",
      d: "M13.5 48.688h37.647"
    }, null, -1)
  ])))
}
export default { render: render }