import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 64 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#a)\"><circle cx=\"32\" cy=\"32.688\" r=\"32\" fill=\"#5DD0CA\"></circle><path stroke=\"#122233\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3\" d=\"M22 33.188v-14c0-5.8 4.701-10.5 10.5-10.5v0c5.799 0 10.5 4.7 10.5 10.5v14\"></path><circle cx=\"32\" cy=\"39.688\" r=\"18\" fill=\"#BEECEA\"></circle><path stroke=\"#122233\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M32 43.688v-7\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 .688h64v64H0z\"></path></clipPath></defs>", 2)
  ])))
}
export default { render: render }