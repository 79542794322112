import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 65 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"32.5\" cy=\"32.688\" r=\"32\" fill=\"#fff\"></circle><mask id=\"a\" width=\"65\" height=\"65\" x=\"0\" y=\"0\" maskUnits=\"userSpaceOnUse\" style=\"mask-type:alpha;\"><circle cx=\"32.5\" cy=\"32.688\" r=\"32\" fill=\"#fff\"></circle></mask><g mask=\"url(#a)\"><rect width=\"28\" height=\"16\" x=\"18.5\" y=\"11.688\" fill=\"#FFA98E\" rx=\"8\"></rect><rect width=\"34\" height=\"16\" x=\"15.5\" y=\"37.688\" fill=\"#27C0B9\" rx=\"8\"></rect></g><path stroke=\"#122233\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M26.5 32.688h12\"></path>", 4)
  ])))
}
export default { render: render }