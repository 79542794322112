import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 65 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "32.5",
      cy: "32.688",
      r: "32",
      fill: "#fff"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "22.9",
      cy: "22.088",
      r: "7.4",
      fill: "#FBD33B"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "43.515",
      cy: "32.134",
      r: "8.986",
      fill: "#27C0B9"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "25.014",
      cy: "44.789",
      r: "6.898",
      stroke: "#122233",
      "stroke-width": "2"
    }, null, -1)
  ])))
}
export default { render: render }