import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 65 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "32.5",
      cy: "32.688",
      r: "32",
      fill: "#fff"
    }, null, -1),
    _createElementVNode("rect", {
      width: "36",
      height: "12",
      x: "14.5",
      y: "18.688",
      fill: "#58D3F4",
      rx: "4"
    }, null, -1),
    _createElementVNode("rect", {
      width: "36",
      height: "12",
      x: "14.5",
      y: "34.688",
      fill: "#7DE0FF",
      rx: "4"
    }, null, -1)
  ])))
}
export default { render: render }