import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 64 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "32",
      cy: "32.688",
      r: "32",
      fill: "#FBD33B"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-width": "1.5",
      d: "M13 43.688h37.647M13 22.688h37.647"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "23",
      cy: "22.688",
      r: "7",
      fill: "#FDE99D"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "41",
      cy: "43.688",
      r: "7",
      fill: "#F9F7F4"
    }, null, -1)
  ])))
}
export default { render: render }