import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 65 65"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"32.5\" cy=\"32.688\" r=\"32\" fill=\"#fff\"></circle><mask id=\"a\" width=\"65\" height=\"65\" x=\"0\" y=\"0\" maskUnits=\"userSpaceOnUse\" style=\"mask-type:alpha;\"><circle cx=\"32.5\" cy=\"32.688\" r=\"32\" fill=\"#fff\"></circle></mask><g mask=\"url(#a)\"><rect width=\"42\" height=\"24\" x=\"-19\" y=\"20.688\" fill=\"#E1A3FF\" rx=\"12\"></rect><rect width=\"42\" height=\"24\" x=\"42\" y=\"20.688\" fill=\"#27C0B9\" rx=\"12\"></rect></g><circle cx=\"33\" cy=\"32.688\" r=\"4\" stroke=\"#000\" stroke-linecap=\"round\" stroke-width=\"2\"></circle>", 4)
  ])))
}
export default { render: render }